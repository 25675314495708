<template>
  <div class="container">
    <nav class="breadcrumb is-large" aria-label="breadcrumbs">
      <ul>
        <li>
          <router-link
            :to="{ name: 'negotiatorStart', params: { id: $route.params.id } }"
          >
            <span>Start</span></router-link
          >
        </li>
        <li>
          <router-link
            :to="{
              name: 'negotiatorPayLess',
              params: { id: $route.params.id }
            }"
          >
            <span>Pay Less</span></router-link
          >
        </li>
        <li class="is-active">
          <router-link
            :to="{
              name: 'negotiatorPayLessSystemError',
              params: { id: $route.params.id }
            }"
          >
            <span>Error</span></router-link
          >
        </li>
      </ul>
    </nav>
    <div class="content">
      <div class="card">
        <header class="card-header">
          <p class="card-header-title">
            <span class="icon is-large">
              <font-awesome-icon
                size="lg"
                icon="exclamation-circle"
                :style="{ color: 'red' }"
              />
            </span>
            <span>{{ instanceConfig.systemErrorTitle }}</span>
          </p>
        </header>
        <div class="card-content">
          <p class="content">
            {{ instanceConfig.systemErrorContent }}
          </p>
        </div>
      </div>
    </div>
    <div class="field">
      <div class="buttons">
        <router-link
          :to="{ name: 'makePayment', params: { id: $route.params.id } }"
          tag="button"
          class="button"
          >Home</router-link
        >
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";

export default {
  computed: {
    ...mapState(["instanceConfig"])
  },
  methods: {
    ...mapActions([
      "fetchPaymentPortalInstanceConfig",
      "fetchUserInfo",
      "fetchMessages"
    ]),
    ...mapActions(["accounts", ["fetchAccounts"]])
  },
  beforeRouteEnter(to, from, next) {
    next(async vm => {
      await vm.fetchPaymentPortalInstanceConfig(vm.$route.params.instanceId);
      if (
        vm.$store.state.userInfo == null ||
        vm.$store.state.userInfo.firstName == null
      ) {
        await vm.fetchUserInfo();
      }
      if (
        vm.$store.state.accounts.accounts == null ||
        vm.$store.state.accounts.accounts.length == 0
      ) {
        await vm.fetchAccounts();
      }
    });
  }
};
</script>
